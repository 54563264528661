export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    alignItems: 'center',
    '.container': {
      alignItems: 'center',
      padding: ['1rem 0rem', '', '', '3rem'],
      '.navItemDropdownMenu': {
        backgroundColor: 'black',
        top: '50px'
      },
      '.hamburger': {
        backgroundColor: 'transparent'
      }
    },
    '.containerScrolled': {
      backgroundColor: 'black',
      padding: '0rem',
      '.navItemDropdownMenu': {
        backgroundColor: 'black',
        top: '50px'
      }
    },
    '.order-online': {
      backgroundColor: 'primary',
      border: '2px ',
      color: 'white',
      padding: '1rem 2rem',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    '.navItemDropdown': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.navItem': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'light',
      a: {
        fontFamily: 'body',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '1rem'
      },
      ':hover': {
        // color: 'inherit',
      }
    },

    '.logo': {
      img: {
        opacity: '1',
        maxHeight: ['80px', '90px']
      }
    },
    '.logoScrolled': {
      position: ['static'],
      padding: '0.5rem 1rem',
      margin: ['0px auto 0px 0px'],
      transform: 'unset',
      img: {
        opacity: '1',
        maxHeight: ['45px', '60px']
      }
    },

    '.hamburger': {
      backgroundColor: 'primary',
      height: '100%',
      width: ['60px', '', '', '80px'],
      height: ['60px', '', '', '80px'],
      padding: '1rem',
      margin: '0rem',
      '> div': {
        backgroundColor: 'white'
      }
    },

    '.hamburgerOpen': {
      backgroundColor: 'primary',
      height: '100%',
      width: ['60px', '', '', '80px'],
      height: ['60px', '', '', '80px'],
      padding: '1rem',
      margin: '0rem',
      '> div': {
        width: '50%',
        backgroundColor: 'white'
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'black',
      padding: '1.5rem'
    },

    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'secondary',
      padding: ['1rem', '', '2rem']
    },
    '.socialIconsContainer, .phoneContainer': {
      padding: '0.25rem',
      margin: '0rem',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      fontFamily: 'body',
      svg: {
        marginRight: '1rem',
        path: {
          fill: 'light',
          width: '25px',
          height: '25px'
        }
      },
      a: {
        justifyContent: 'flex-start',
        fontSize: ['0.8rem', '1rem'],
        padding: ['0.5rem 0rem', '', '1rem 0rem'],
        margin: '0rem'
      }
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: '#e6ddc0',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['', '', '', 'stretch'],
    '::after': {
      content: `"© ${new Date().getFullYear()} Bridgeport Flyer Diner"`,
      width: '100%',
      backgroundColor: '#202020',
      color: 'white',
      padding: '2rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '200px'
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      color: 'black',
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', '350px']
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },
    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'primary',
      margin: ['', '', '', '0 0 0 auto'],
      marginBottom: '0rem',
      padding: ['', '', '4rem 3rem', '4rem 4rem'],
      width: ['100%', '', '', '40%'],
      '.sectionHeading': {
        color: 'white',
        fontSize: '2rem',
        textAlign: 'left',
        width: '100%',
        padding: ['1.25rem', '', '', '0rem'],
        fontWeight: '400'
      },

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'white',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.poweredBy  .gonationLogo': {
      filter: 'unset'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'black'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      color: 'text'
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    }
  },

  ctaWidget: {
    display: ['', '', '', 'none!important'],
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: '100%',
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2.5rem',
    order: '1',
    color: 'primary',
    textAlign: 'center',
    marginBottom: '0.5rem',
    fontFamily: 'heading',
    textTransform: 'capitalize',
    textShadow: '2px 2px black'
  },

  subtitle: {
    color: 'black',
    fontWeight: 'bold',
    order: '2',
    fontFamily: 'display',
    fontSize: ['1.25rem', '', '2rem'],
    fontFamily: 'body',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    border: 'none'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    position: 'relative',
    margin: '1rem 1rem 0rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: ['', '', '75vh']
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      order: ['', '', '2'],
      width: ['', '', '45%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem',
      height: 'auto'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3.5rem', '4.5rem'],
      border: 'none',
      padding: '0rem',
      textShadow: '2px 2px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white',
        margin: '0rem'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    zIndex: ['1'],
    // maxHeight: ['', '', '50vh'],
    margin: '1rem',
    width: 'calc(100% - 2rem)',
    '.imageContainer': {
      width: ['', '', '20%'],
      order: ['2', ''],
      img: {
        boxShadow: '2px 4px 13px #8a8a8a'
      }
    },
    '.content': {
      padding: '2rem',
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', ''],
      backgroundColor: 'black',
      border: 'solid 2px',
      borderColor: 'secondary',
      width: ['', '', '80%'],
      boxShadow: '2px 4px 13px #8a8a8a'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '0rem 0rem 1rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset',
      color: 'white'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'primary',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  titleBlock: {
    padding: '5rem 1rem 7rem',
    '.title': {
      variant: 'customVariants.title',
      margin: '0 auto',
      fontSize: ['2rem', '', '3rem'],
      fontFamily: 'display',
      textTransform: 'capitalize',
      fontWeight: 'normal'
    }
  },

  homepageBoxes1: {
    '.box': {
      border: 'solid 1px',
      width: ['', '', '', 'calc(33.3% - 2rem)'],
      margin: '1rem',
      overflow: 'hidden',
      padding: '0rem 0rem 1rem',
      transition: 'all ease-in-out 0.4s',
      img: {
        height: ['250px', '', '300px', '350px'],
        objectFit: 'cover'
      },
      '.title': {
        variant: 'customVariants.title',
        padding: '0rem 1rem'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        padding: '0rem 1rem'
      },
      '.text': {
        variant: 'customVariants.text',
        padding: '0rem 1rem'
      },
      ':hover': {
        backgroundColor: 'lightgrey'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    background: 'unset',
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    // '.menuContainer': {
    //   // border: 'solid 1px black',
    //   // padding: ['1rem', '1rem'],
    //   // margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    //   // width: ['calc(100% - 1rem)'],
    //   // backgroundColor: 'white',
    //   // paddingBottom: '1rem',
    //   padding: '0rem',
    // },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    // '.menuItemInnerContainer, .menuItemContentContainer': {
    //   margin: '0rem',
    // },
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      width: '100%',
      borderTop: 'solid 2px',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem 2rem'],
      padding: '1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.menuItemName': {
      color: 'primary'
    },

    '.menuItemDescription': {
      color: 'black'
    }
    // '.menuPriceContainer': {
    //   // display: 'none',
    // },
    // '.variantsContainer': {
    //   // display: 'none',
    // },
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'secondary',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['2rem 1rem', '2rem 1rem', '2rem 1rem'],
    backgroundColor: 'white',
    '.boxesHeading': {
      display: 'none'
    },
    '.boxesContainer': {
      justifyContent: 'center'
    },
    '.imageFill': {
      paddingBottom: '50%'
    },
    '.imageContainer': {
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      fontSize: '1.2rem'
    },
    '.subtitle': {
      fontSize: '1rem',
      textTransform: 'capitalize'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {
    '.boxesHeading': {
      display: 'none'
    },
    padding: ['2rem 1rem', '2rem 1rem', '2rem 1rem'],
    '.boxesContainer': {
      justifyContent: 'center'
    },
    '.box': {
      maxWidth: ['', '', '50%']
    },
    '.imageFill': {
      paddingBottom: '60%'
    },
    '.textContent': {
      left: '0px',
      backgroundColor: '#000000cc'
    }
  },

  teamPage: {
    '.title': {
      fontSize: '1.75rem',
      marginBottom: '2rem'
    },
    '.subtitle': {
      fontSize: '1.25rem',
      textTransform: 'capitalize'
    },
    '.imageContainer': {
      width: ['', '', '35%']
    },
    '.contentRow': {
      width: ['', '', '65%'],
      paddingTop: '2rem'
    }
  },

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
