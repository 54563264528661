export default {
  heading: 'Damion, serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Faster One, sans-serif',
  googleFonts: ['Open Sans:100,200,300,400,500,600,700,800,900', 'Damion', 'Faster One']
  // customFamilies: ['Stannum'],
  // customUrls: [
  //   'https://gonation.biz/fonts/stannum-regular/stannum-regular.css',
  // ],
}
